export const Text = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sodales urna orci, id pellentesque nulla mattis eu. Integer sit amet fermentum nibh. Nulla gravida sodales quam vel auctor. Praesent semper quis dui sit amet volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas volutpat tortor eget congue dignissim. Integer aliquam, lacus ut venenatis suscipit, lacus urna mattis mauris, ut varius est odio non enim. Curabitur tincidunt vitae odio eget malesuada. Phasellus eu ultrices diam. Etiam sit amet varius felis. Aenean eu porttitor justo. Maecenas fringilla nunc velit, id auctor orci consectetur vel. Curabitur quis nisi elit. Fusce ac ante et sem rutrum imperdiet vitae non felis. Phasellus commodo nec elit et rhoncus. In eleifend sollicitudin turpis, at tristique nisl.",
    "Aenean quis sagittis sapien, quis suscipit elit. Proin vel commodo tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lectus risus, finibus a volutpat in, pharetra rhoncus nisl. Maecenas sed risus quis mi faucibus sodales. Praesent ut suscipit augue. Maecenas et dictum ex. Nulla mollis enim quis tellus pharetra, id viverra diam faucibus.",
    "Ut convallis vel ex quis pulvinar. Nunc pharetra euismod volutpat. Maecenas faucibus bibendum elementum. Duis feugiat eros metus, eu laoreet tellus efficitur vel. Aenean porta est eget mollis tempor. Etiam luctus bibendum tempus. Fusce id gravida nulla. Mauris id turpis orci. Pellentesque efficitur vestibulum mi, cursus pharetra sem finibus sit amet. Vivamus interdum arcu eget ex maximus venenatis. Etiam aliquet tincidunt finibus.",
    "Suspendisse nunc justo, iaculis elementum viverra eget, posuere sit amet risus. Aliquam ac lectus vitae nisl elementum hendrerit. Nunc dignissim dolor ut felis tempus, quis tempor neque euismod. Praesent ut erat eget justo malesuada tempus in ut neque. Cras enim metus, eleifend dignissim lacinia eget, pulvinar vitae arcu. Aliquam posuere lectus in sapien placerat commodo. Cras eu quam eu augue sagittis mollis eget et tortor. Integer sit amet sagittis felis. Maecenas eu maximus orci, quis lacinia leo. Integer convallis aliquet suscipit. Duis odio libero, condimentum nec lacus sed, eleifend pulvinar neque. Nunc bibendum mauris ipsum, et imperdiet odio dignissim vel. Fusce ante lacus, sollicitudin ut ipsum sit amet, lacinia elementum enim. Suspendisse tincidunt, nisi vitae dapibus mattis, tellus purus tristique magna, eget facilisis magna nulla sed ex. Morbi finibus nulla libero, nec vehicula justo imperdiet non. Morbi id turpis vitae turpis fermentum rutrum.",
    "Nullam ac felis ac lacus placerat ultrices quis id diam. Aenean viverra porta odio at faucibus. Duis mattis libero et feugiat efficitur. Ut eu laoreet erat, vel facilisis sapien. Vivamus porta rhoncus mauris quis vestibulum. Nulla mattis, sapien at ultricies iaculis, nibh mi tempus elit, non tristique est ex ac tellus. Duis convallis malesuada dictum. Phasellus ullamcorper elementum dui, condimentum vulputate nulla luctus sed. In eget tincidunt lorem, ut suscipit odio. Mauris ac aliquet eros, vitae mattis risus. Ut dictum hendrerit nulla, eu luctus lacus luctus sed.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sodales urna orci, id pellentesque nulla mattis eu. Integer sit amet fermentum nibh. Nulla gravida sodales quam vel auctor. Praesent semper quis dui sit amet volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas volutpat tortor eget congue dignissim. Integer aliquam, lacus ut venenatis suscipit, lacus urna mattis mauris, ut varius est odio non enim. Curabitur tincidunt vitae odio eget malesuada. Phasellus eu ultrices diam. Etiam sit amet varius felis. Aenean eu porttitor justo. Maecenas fringilla nunc velit, id auctor orci consectetur vel. Curabitur quis nisi elit. Fusce ac ante et sem rutrum imperdiet vitae non felis. Phasellus commodo nec elit et rhoncus. In eleifend sollicitudin turpis, at tristique nisl.",
    "Aenean quis sagittis sapien, quis suscipit elit. Proin vel commodo tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lectus risus, finibus a volutpat in, pharetra rhoncus nisl. Maecenas sed risus quis mi faucibus sodales. Praesent ut suscipit augue. Maecenas et dictum ex. Nulla mollis enim quis tellus pharetra, id viverra diam faucibus.",
    "Ut convallis vel ex quis pulvinar. Nunc pharetra euismod volutpat. Maecenas faucibus bibendum elementum. Duis feugiat eros metus, eu laoreet tellus efficitur vel. Aenean porta est eget mollis tempor. Etiam luctus bibendum tempus. Fusce id gravida nulla. Mauris id turpis orci. Pellentesque efficitur vestibulum mi, cursus pharetra sem finibus sit amet. Vivamus interdum arcu eget ex maximus venenatis. Etiam aliquet tincidunt finibus.",
    "Suspendisse nunc justo, iaculis elementum viverra eget, posuere sit amet risus. Aliquam ac lectus vitae nisl elementum hendrerit. Nunc dignissim dolor ut felis tempus, quis tempor neque euismod. Praesent ut erat eget justo malesuada tempus in ut neque. Cras enim metus, eleifend dignissim lacinia eget, pulvinar vitae arcu. Aliquam posuere lectus in sapien placerat commodo. Cras eu quam eu augue sagittis mollis eget et tortor. Integer sit amet sagittis felis. Maecenas eu maximus orci, quis lacinia leo. Integer convallis aliquet suscipit. Duis odio libero, condimentum nec lacus sed, eleifend pulvinar neque. Nunc bibendum mauris ipsum, et imperdiet odio dignissim vel. Fusce ante lacus, sollicitudin ut ipsum sit amet, lacinia elementum enim. Suspendisse tincidunt, nisi vitae dapibus mattis, tellus purus tristique magna, eget facilisis magna nulla sed ex. Morbi finibus nulla libero, nec vehicula justo imperdiet non. Morbi id turpis vitae turpis fermentum rutrum.",
    "Nullam ac felis ac lacus placerat ultrices quis id diam. Aenean viverra porta odio at faucibus. Duis mattis libero et feugiat efficitur. Ut eu laoreet erat, vel facilisis sapien. Vivamus porta rhoncus mauris quis vestibulum. Nulla mattis, sapien at ultricies iaculis, nibh mi tempus elit, non tristique est ex ac tellus. Duis convallis malesuada dictum. Phasellus ullamcorper elementum dui, condimentum vulputate nulla luctus sed. In eget tincidunt lorem, ut suscipit odio. Mauris ac aliquet eros, vitae mattis risus. Ut dictum hendrerit nulla, eu luctus lacus luctus sed.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sodales urna orci, id pellentesque nulla mattis eu. Integer sit amet fermentum nibh. Nulla gravida sodales quam vel auctor. Praesent semper quis dui sit amet volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas volutpat tortor eget congue dignissim. Integer aliquam, lacus ut venenatis suscipit, lacus urna mattis mauris, ut varius est odio non enim. Curabitur tincidunt vitae odio eget malesuada. Phasellus eu ultrices diam. Etiam sit amet varius felis. Aenean eu porttitor justo. Maecenas fringilla nunc velit, id auctor orci consectetur vel. Curabitur quis nisi elit. Fusce ac ante et sem rutrum imperdiet vitae non felis. Phasellus commodo nec elit et rhoncus. In eleifend sollicitudin turpis, at tristique nisl.",
    "Aenean quis sagittis sapien, quis suscipit elit. Proin vel commodo tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lectus risus, finibus a volutpat in, pharetra rhoncus nisl. Maecenas sed risus quis mi faucibus sodales. Praesent ut suscipit augue. Maecenas et dictum ex. Nulla mollis enim quis tellus pharetra, id viverra diam faucibus.",
    "Ut convallis vel ex quis pulvinar. Nunc pharetra euismod volutpat. Maecenas faucibus bibendum elementum. Duis feugiat eros metus, eu laoreet tellus efficitur vel. Aenean porta est eget mollis tempor. Etiam luctus bibendum tempus. Fusce id gravida nulla. Mauris id turpis orci. Pellentesque efficitur vestibulum mi, cursus pharetra sem finibus sit amet. Vivamus interdum arcu eget ex maximus venenatis. Etiam aliquet tincidunt finibus.",
    "Suspendisse nunc justo, iaculis elementum viverra eget, posuere sit amet risus. Aliquam ac lectus vitae nisl elementum hendrerit. Nunc dignissim dolor ut felis tempus, quis tempor neque euismod. Praesent ut erat eget justo malesuada tempus in ut neque. Cras enim metus, eleifend dignissim lacinia eget, pulvinar vitae arcu. Aliquam posuere lectus in sapien placerat commodo. Cras eu quam eu augue sagittis mollis eget et tortor. Integer sit amet sagittis felis. Maecenas eu maximus orci, quis lacinia leo. Integer convallis aliquet suscipit. Duis odio libero, condimentum nec lacus sed, eleifend pulvinar neque. Nunc bibendum mauris ipsum, et imperdiet odio dignissim vel. Fusce ante lacus, sollicitudin ut ipsum sit amet, lacinia elementum enim. Suspendisse tincidunt, nisi vitae dapibus mattis, tellus purus tristique magna, eget facilisis magna nulla sed ex. Morbi finibus nulla libero, nec vehicula justo imperdiet non. Morbi id turpis vitae turpis fermentum rutrum.",
    "Nullam ac felis ac lacus placerat ultrices quis id diam. Aenean viverra porta odio at faucibus. Duis mattis libero et feugiat efficitur. Ut eu laoreet erat, vel facilisis sapien. Vivamus porta rhoncus mauris quis vestibulum. Nulla mattis, sapien at ultricies iaculis, nibh mi tempus elit, non tristique est ex ac tellus. Duis convallis malesuada dictum. Phasellus ullamcorper elementum dui, condimentum vulputate nulla luctus sed. In eget tincidunt lorem, ut suscipit odio. Mauris ac aliquet eros, vitae mattis risus. Ut dictum hendrerit nulla, eu luctus lacus luctus sed.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sodales urna orci, id pellentesque nulla mattis eu. Integer sit amet fermentum nibh. Nulla gravida sodales quam vel auctor. Praesent semper quis dui sit amet volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas volutpat tortor eget congue dignissim. Integer aliquam, lacus ut venenatis suscipit, lacus urna mattis mauris, ut varius est odio non enim. Curabitur tincidunt vitae odio eget malesuada. Phasellus eu ultrices diam. Etiam sit amet varius felis. Aenean eu porttitor justo. Maecenas fringilla nunc velit, id auctor orci consectetur vel. Curabitur quis nisi elit. Fusce ac ante et sem rutrum imperdiet vitae non felis. Phasellus commodo nec elit et rhoncus. In eleifend sollicitudin turpis, at tristique nisl.",
    "Aenean quis sagittis sapien, quis suscipit elit. Proin vel commodo tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lectus risus, finibus a volutpat in, pharetra rhoncus nisl. Maecenas sed risus quis mi faucibus sodales. Praesent ut suscipit augue. Maecenas et dictum ex. Nulla mollis enim quis tellus pharetra, id viverra diam faucibus.",
    "Ut convallis vel ex quis pulvinar. Nunc pharetra euismod volutpat. Maecenas faucibus bibendum elementum. Duis feugiat eros metus, eu laoreet tellus efficitur vel. Aenean porta est eget mollis tempor. Etiam luctus bibendum tempus. Fusce id gravida nulla. Mauris id turpis orci. Pellentesque efficitur vestibulum mi, cursus pharetra sem finibus sit amet. Vivamus interdum arcu eget ex maximus venenatis. Etiam aliquet tincidunt finibus.",
    "Suspendisse nunc justo, iaculis elementum viverra eget, posuere sit amet risus. Aliquam ac lectus vitae nisl elementum hendrerit. Nunc dignissim dolor ut felis tempus, quis tempor neque euismod. Praesent ut erat eget justo malesuada tempus in ut neque. Cras enim metus, eleifend dignissim lacinia eget, pulvinar vitae arcu. Aliquam posuere lectus in sapien placerat commodo. Cras eu quam eu augue sagittis mollis eget et tortor. Integer sit amet sagittis felis. Maecenas eu maximus orci, quis lacinia leo. Integer convallis aliquet suscipit. Duis odio libero, condimentum nec lacus sed, eleifend pulvinar neque. Nunc bibendum mauris ipsum, et imperdiet odio dignissim vel. Fusce ante lacus, sollicitudin ut ipsum sit amet, lacinia elementum enim. Suspendisse tincidunt, nisi vitae dapibus mattis, tellus purus tristique magna, eget facilisis magna nulla sed ex. Morbi finibus nulla libero, nec vehicula justo imperdiet non. Morbi id turpis vitae turpis fermentum rutrum.",
    "Nullam ac felis ac lacus placerat ultrices quis id diam. Aenean viverra porta odio at faucibus. Duis mattis libero et feugiat efficitur. Ut eu laoreet erat, vel facilisis sapien. Vivamus porta rhoncus mauris quis vestibulum. Nulla mattis, sapien at ultricies iaculis, nibh mi tempus elit, non tristique est ex ac tellus. Duis convallis malesuada dictum. Phasellus ullamcorper elementum dui, condimentum vulputate nulla luctus sed. In eget tincidunt lorem, ut suscipit odio. Mauris ac aliquet eros, vitae mattis risus. Ut dictum hendrerit nulla, eu luctus lacus luctus sed."
]