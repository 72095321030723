import React, {useState, useEffect} from 'react';
import logo from '../logo.svg';
import '../App.css';
import Values from 'values.js'
import styled from 'styled-components';
import {NavLink, Link} from 'react-router-dom'
import Sidebar, {Overlay} from './sidebar'
import {FaBars} from 'react-icons/fa'
import {ContextUser} from '../context'
import Logo from '../assets/sdu.PNG'

const HeaderNav = styled.nav`
display:flex;
justify-content:center;
align-items:center;
height: 7rem;
background: #45abaa;
background: #182c2c;
color: var(--text-color2);
position: sticky;
top:0;
z-index : 10000;
// width:100vw;

.logo{
    margin-top: 0.5rem;
    width: 10rem
}
.nameLogo{
    font-family: arial;
    font-weight: 100;
    text-decoration:none;
    color: #fff
}
.nameLogo2{
    font-size: 1.35rem;
    font-family: arial;
    font-weight: 100;
    text-decoration:none;
    color: #aaa
}
// .heading: active{
//     color: #ddd
// }
// h3{
//     color:#444;
//     position: absolute:
//     left: 1rem;
// }
.link{
    color: var(--text-color2);
    color: #444;
    border-radius: 0.2rem;
    text-decoration:none;
    transition: all 0.5s;
    font-size: 1rem;
    margin: 1rem;
    padding : 0.5rem;
    background: #bcb
}
.link:hover{
    color: #000
}
.menu-btn{
    font-size: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.8rem;
    display:none;
    color: #ddd;
    background : none
}

.link-btn{
    background:none;
    padding: 0.5rem;
    color: #bbb;
    text-decoration:none;
    transition: all 0.5s;
    font-size: 1rem;
    margin: 0rem 1rem;
    float : left
}
.active{
    color : #fff;
    transition: all 5s;
    background : none;

}
// .active::after{
//     content: '';
//     position: absolute;
//     height: 2px;
//     width: 100px;
//     background: var(--text-color);
//     background: red;
//     transform: translate(-100%, 1rem);
//     animation: anim1 1s
// }

.copiednote{
    z-index: 10000;
    padding: 40vh 0;
    height: 100vh; 
    position: fixed;
    top: 0; 
    width: 100vw;
    opacity:0.85;
    background: black;
    fontSize: 2rem; 
    animation: zoom2 1s ease-out;
    color: white;
    text-transform: uppercase
}
.copiednote2{
    z-index: 10000;
    padding: 40vh 0;
    height: 100vh; 
    position: fixed;
    top: 0; 
    width: 100vw;
    fontSize: 2rem; 
    animation: zoom1 1.5s ease-out
}
.undo{
	display: none
}
@keyframes zoom1{
    from{font-size: 1rem}
    to{font-size: 5rem; width: 100vw; height: 100vh}
}
.copiednote h3{
    margin: 0
}

.copiedImgnote{
    z-index: 10000;
    padding: 40vh 0;
    height: 100vh; 
    position: fixed;
    top: 0; 
    width: 100vw;
    opacity:0.85;
    background: black;
    fontSize: 2rem; 
    animation: zoom2 1s ease-out;
    ${'' /* background: var(--backgnd-color); */}
    color: white;
    text-transform: uppercase
}
@keyframes zoom2{
    from{font-size: 1rem}
    to{font-size: 3.5rem; width: 100vw; height: 100vh}
}
@keyframes anim1{
    from{width: 0px}
    to{width: 100px}
}
.sidebar{
    display:none
}
@media screen and (max-width: 900px){
    height : 5rem;
    .links{
        display:none
    }
    .menu-btn{
        display: block;
        top: 1.8rem;
    }
    .sidebar{
        display:block
    }
}
`

const Header =()=>{
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isOverlay, setIsOverlay] = useState(false)
    const {copiedColor, copiedText, hex, index, copiedImg} = ContextUser()


    const setMenu=()=>{
        setIsMenuOpen(true)
        setIsOverlay(true)
    }
    const closeMenu =()=>{
        setIsMenuOpen(false)
        setIsOverlay(false)
    }
     
  
    return (
        <HeaderNav>
        {isOverlay && <Overlay closeMenu={closeMenu}/>}
        <div className='menu-btn' onClick={setMenu}>
            <FaBars />
        </div>
        <Sidebar mode={isMenuOpen} closeMenu={closeMenu} className='sidebar'/>
       {copiedColor && <><div className='copiednote' style={{color: `${index > 10 ? 'white' : 'black'}`, background:`#${hex}`}}>
      <h3>COPIED</h3>
       <div>#{hex}</div>
       </div>
       </>}
       {copiedText && <><div className='copiednote' >
      <h3>COPIED</h3>
       </div>
       </>}
        {copiedImg && <><div className='copiedImgnote' >
      <div>Image URL</div><div>copied</div>
       </div>
       </>}
        <Link className='nameLogo' style={{textDecoration:"none", color:"#ddd", 
        fontSize:"1.5rem"}} to='/' ><img src={Logo} className='logo' alt="SDU"/></Link>
              <ul className='links'>
                <NavLink to='/colors' className='link-btn' activeClassName='active'>Color Shades</NavLink>
                <NavLink to='/loren' className='link-btn' activeClassName='active'>Loren Ipsun</NavLink>
                <NavLink to='/photos' className='link-btn' activeClassName='active'>Stock Photos</NavLink>
              </ul>
        </HeaderNav>
    )
}

export default Header
