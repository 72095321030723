import React, {useState} from 'react';
import styled from 'styled-components';
import '../App.css';
import {FaRegCopy} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {ContextUser} from '../context'

export const Photo =({item})=>{
const [copied, setCopied] = useState(false)
const {setCopiedImg} = ContextUser()

const setCopiedFunc=()=>{
    setCopiedImg(true)
}
    const {likes, urls: {regular}, user: {bio, name, profile_image:{small}}, links: {html}} = item
    return (
        <div className='picture_box'  >
            <div className='imgBBox'>
            <img src ={regular} alt={name} className='image'/>
            <FaRegCopy className='copy' onClick={()=>{
                setCopiedFunc()
                navigator.clipboard.writeText(regular)
            }}/>
                <div className='imageInfo'>
                    <div className='userName'><span className='pic-key'>Uploaded by:</span> {`${name || 'Anonnymous'}`}</div>
                    <img className='userImg' src = {small} alt='Image Anonnymous'/>
                    <div><div className='bio'><span className='pic-key'>Bio:</span> {`${(bio && bio.slice(0, 40).toLowerCase() + '...') || ' '}`}</div></div>
                    {/* <Link className='bioLink' to={html}>More about <span>{`${name || 'the contributor'}`}</span></Link> */}
                </div> 
            </div>   
        </div>
    )
}


