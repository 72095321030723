import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import dotenv from 'dotenv'
import Axios from 'axios'
import '../App.css';
import {FaRegCopy, FaSearch} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {Photo} from '../components/photo'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// https://api.unsplash.com/search/photos/?client_id=WTw-79X5QQiL2m0iEP0XcPZ80JWFkZ29lJa1v0O3_nA&query=cat
const url = `https://api.unsplash.com/photos/?client_id=`
const searchUrl = `https://api.unsplash.com/search/photos/?client_id=`
//https://api.unsplash.com/search/photos?page=1&query=office

const Container = styled.div`
    min-height: 100vh;
    margin: 0 7%;
    font-size: 1.4rem;
    color: #ddd;
.innerContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1px
    }
.picture_box{
    width: 250px;
    position:static;
    overflow: hidden;
    z-index:1000
}
.imgBBox{
    overflow: hidden;
    position:relative
}
.form-search{
    width: 100%;
    padding : 0.7rem;
    font-size: 1.2rem;
    font-weight : 600;
    color: #444
}
.search-btn{
    top: 0.6rem;
    cursor: pointer;
    margin-left: -1rem
}
.image{
    width: 250px;
    height: 250px;
    position:relative;
    transition: all 0.8s;
    position: relative;
    object-fit:cover
}
.copy{
    color: #fff;
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.3rem;
    cursor : pointer
}
.copy:hover{
    transform: scale(1.1);
    color: #555
}
.image:hover{
    transform: scale(1.2)
}
.imageInfo{
    transform:translateY(50%);
    transition: all 1s;
    z-index: -1;
    position:absolute;
    background: rgba(10,10,10, 0.7);
    width: 100%;
    padding: 1rem 0
}

.picture_box:hover .imageInfo{
    transform: translateY(-100%);
    z-index: 1;
}
.linkBox{
    position: absolute;
    width: 100%;

}
.bio{
    font-size: 0.9rem;
    color: #ddd;
    margin: 0.4rem
}
.userImg{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    float : left;
    margin: 0.8rem;
}
.bioLink{
    text-decoration:none;
    font-size: 0.8rem;
    opacity: 0.8;
    color: #34e
}
.bioLink span{
    color: white;
    opacity: 1;
}
.bioLink span:after{
    content:'';
    background: blue;
    width:20px;
    height: 3px;
    position:absolute;
    transition:all 2.5s;
    transform: translateY(700%)
}
.picture_box:hover span:after{
    width: 400px;
    transform: translate(-20%, 700%)
}
.bioLink: hover{
    opacity: 0.8
}
.copiednote{
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
    z-index:1;
    top: 1rem;
    position: absolute;
     background: rgb(10,10,10,0.5);
    padding: 1.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-iten: center;
    animation: zoom1 2s linear
}

@keyframes zoom1{
    from{width: 100vw}
    to{width: 50vw}
}
.info{
    color: #bbb;
  }
  .page-title{
    font-size: 1.2rem;
    color : #ddd
  }

  .userName, .bio{
      font-size: 1rem;
      text-align: left;
      margin-left: 0.7rem
  }
  .pic-key{
      color:#ccc;
  }

@media screen and (max-width: 590px){
    .innerContainer{
        grid-template-columns:  repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 5px
    }
    .picture_box{
    width: 150px;
    }
    .image{
        width: 150px;
        height: 150px
    }
    .form-search{
        width: 100%;
    }
    .bio{
        display:none
    }
}
@media screen and (max-width: 360px){
    .innerContainer{
        display: block
    }
    .picture_box{
    width: 100%;
    }
    .image{
        width: 100%;
        height: 100%
    }
    .form-search{
        width: 100%;
    }
}
`

const Photos = ()=>{
    const [name, setName] = useState('')
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    
    const [search, setSearch] = useState('')
    const [term, setTerm] = useState('')

    const access_key=process.env.REACT_APP_ACCESS_KEY

    const fetchImages = async()=>{
        setIsLoading(true)

        if(search){           
             let imagesResponse = await Axios(`${searchUrl}${access_key}&query=${search}&per_page=${perPage}&page=${page}`).catch((error)=>{console.log(error)})
              if(page== 1){
                  setData(imagesResponse.data.results)
              }else{
                  setData(prev => {   
                    return [...prev,...imagesResponse.data.results]
                })
              }
        }else{
            let imagesResponse = await Axios(`${url}${access_key}&page=${page}&per_page=${perPage}`).catch((error)=>{console.log(error)})
               setData(prev => {
                    return [...prev, ...imagesResponse.data]
                })
           
            
        }
           
        setIsLoading(false)
    }

useEffect(()=>{
    window.scrollTo(0,0)
},[])

useEffect(()=>{
    const windowScroll = window.addEventListener('scroll', handleScroll)
},[])

const handleScroll = ()=>{
    
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    const windowScrolled = window.scrollY

    if((windowScrolled + windowHeight) >= (documentHeight-10)){
        setIsLoading(true)
        setPage(prev =>{
            return prev + 1
        }) 

    }
}


const handleSearch =(e)=>{
    e.preventDefault()
    setSearch(term)
    setData([])
    setPage(1)
}
const setTermFunc = (e)=>{
    setTerm(e.target.value)

}
useEffect(()=>[
        fetchImages()
],[page,search])   

return <Container id='itemContainer'>
         <h3 className='page-title'>Access thousands of stock images free:</h3>
         <p className='info'>Search for pictures by name</p>
        <form onSubmit={handleSearch}>
            <div className='search_box'>
            <input type='search' value={term} onChange={setTermFunc} className='form-search' placeholder='Search for pictures...'/>
            <button className='search-btn' type='submit' ><FaSearch /></button>
            </div>
        </form>
        <div className='innerContainer'>
       
            {data.map(item =>{
                const {id} = item
                return <Photo key={id} item={item}/>
            })}
        </div>
        {isLoading && <Loader type="Rings" color="white" height={100} width={100}
        timeout={100000} />}
    </Container>
}

export default Photos