import React, {useState, useEffect} from 'react';
import logo from '../logo.svg';
import '../App.css';
import Values from 'values.js'
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom'
import {FaWindowClose} from 'react-icons/fa'
import Logo from '../assets/sdu.PNG'

const SideBar = styled.nav`
    z-index: 2000;
    width: 83%;
    position: absolute;
    
.links-list{
    text-decoration: none;
    height: 100vh;
    margin: 0;
    padding: 20vh 0;
    justify-content: center;
    align-items:
}
.link-btn{
    background:none;
    padding: 0.5rem;
    color: #bbb;
    text-decoration:none;
    transition: all 0.5s;
    font-size: 1rem;
    margin: 0rem 1rem;
    float : left
}
.link:hover{
    color: #fff;
    margin-left: 20px
}
.overlay{
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

    position: fixed;
    top: 0rem;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(20,20,20, 0.7);
    height: 100vh;
    width: 100vw;
    z-index: 100;
    

}
  .closeMenu{
      color: #ddd;
      font-size: 1.5rem;
      position: absolute;
      right: 1.5rem;
      top: 1.8rem;
  }
  .active{
    color : #fff;
    transition: all 5s;
    background : none;
}
  @media screen and (max-width: 230px){
      .link{font-size: 0.9rem}
  }
`
export const Overlay =({closeMenu})=>{
    return (
        <SideBar onClick={closeMenu} >
        <div className='overlay'>
        </div>
        </SideBar>
    )
}

const Sidebar =({mode,closeMenu})=>{
    return (
        <SideBar className={`${ mode ? 'sidebar slide' : 'sidebar'}`}>
        <span className='closeMenu' onClick={()=>{
            closeMenu()
        }}><FaWindowClose/></span>
         <Link className='nameLogo' style={{textDecoration:"none", color:"#ddd", 
        fontSize:"1.5rem"}} to='/' ><img src={Logo} className='logo' alt="SDU"/></Link>
        <div>
            <ul className='links-list'>
                <NavLink to='/colors' className='link-btn' onClick={closeMenu} activeClassName='active'>Color Shades</NavLink><br/><br/>
                <NavLink to='/loren' className='link-btn' onClick={closeMenu} activeClassName='active'>Loren Ipsun</NavLink><br/><br/>
                <NavLink to='/photos' className='link-btn' onClick={closeMenu} activeClassName='active'>Stock Photos</NavLink>
              </ul>
        </div>
        </SideBar>
    )
}


export default Sidebar