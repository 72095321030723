import React from 'react'
import styled from 'styled-components';

const FootDiv = styled.div`
    background: linear-gradient(to top, #000, #333);
    position: fixed;
    bottom: 0;
    z-index: 1000;
    height: 5rem;
    width: 100%;
    color: #999;
    padding: 1rem;
    box-sizing: border-box;
    font-size: 0.9rem;    
    .name{
        color: #fff;
        margin-left: 10px
    }
 
    
`


const Footer = ()=>{
    return (
        <FootDiv>
            <div>Developed by<span className='name'> Smart Egbuchulem</span></div>
           &copy; {new Date().getFullYear()}
        </FootDiv>
    )
}

export default Footer

